<template>
  <div class="uk-margin-large-top bg-login">
    <div class="bg-front" />
    <div class="uk-card uk-card-default uk-card-body login-card">
      <div class="content-wrapper">
        <div class="logo-wrapper">
          <router-link
            class="uk-text-decoration-none"
            to="/login"
          >
            <img
              v-lazy="`${images}/icon/back-arrow.svg`"
              alt="icon arrow"
            >
            <h3 class="uk-display-inline uk-margin-remove-bottom-s">
              Masuk Akun
            </h3>
          </router-link>
        </div>
        <h1>Lupa Password</h1>
        <h2>Kami akan mengirimkan sebuah link ke email Anda untuk menyetel ulang password.</h2>
        <form @submit.prevent="onSubmit">
          <div class="uk-margin uk-text-left">
            <h4>Email</h4>
            <div class="uk-inline uk-width-1-1">
              <input
                v-model="email"
                v-validate="'required|email'"
                name="email"
                class="uk-input"
                type="text"
                placeholder="Masukkan email..."
                :class="{'uk-form-danger': errors.has('email')}"
              >
            </div>
            <span
              v-show="errors.has('email')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('email') }}</span>
          </div>
          <button
            v-if="!is_loading"
            class="uk-button uk-button-primary uk-width-1-1"
            type="submit"
            :disabled="is_loading"
          >
            Kirim
          </button>
          <button
            v-else
            class="uk-button uk-button-primary uk-width-1-1"
            type="button"
            disabled
          >
            <div uk-spinner />
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data() {
    return {
      is_loading: false,
      email: '',
      images: PREFIX_IMAGE
    }
  },
  methods: {
    ...mapActions({
      forgotPassword: 'auth/forgot'
    }),
    onSubmit() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.forgot()
        }
      })
    },
    async forgot() {
      this.is_loading = true
      await this.forgotPassword({
        email: this.email
      })
      this.is_loading = false
    }
  }
}
</script>

<style scoped>

.logo-wrapper {
  display: flex;
  align-items: baseline;
  margin: 0 0 104px;
}
h1 {
  text-align: left;
  font-family: ClanOT;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #025231;
}
h2 {
  text-align: left;
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #1F2E28;
}
h3 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-left: 10px;
}
h4 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  color: #025231;
  margin-bottom: 5px;
}
.uk-margin-large-top {
  width: 100%;
  max-width: 100%;
  display: flex;
  margin-top: 0 !important;
}

</style>
